@font-face {
    font-family: 'ABC Favorit Pro Book';
    src: url('../fonts/ABCFavoritPro-Book.eot');
    src: url('../fonts/ABCFavoritPro-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavoritPro-Book.woff2') format('woff2'),
        url('../fonts/ABCFavoritPro-Book.woff') format('woff'),
        url('../fonts/ABCFavoritPro-Book.ttf') format('truetype'),
        url('../fonts/ABCFavoritPro-Book.svg#ABCFavoritPro-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grato Grotesk DEMO';
    src: url('../fonts/GratoGroteskDEMO-Bold.eot');
    src: url('../fonts/GratoGroteskDEMO-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GratoGroteskDEMO-Bold.woff2') format('woff2'),
        url('../fonts/GratoGroteskDEMO-Bold.woff') format('woff'),
        url('../fonts/GratoGroteskDEMO-Bold.ttf') format('truetype'),
        url('../fonts/GratoGroteskDEMO-Bold.svg#GratoGroteskDEMO-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
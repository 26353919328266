@import '../../../styles/vars';

.btn {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: $primary_font;
    margin: 0;
    padding: 0;
    transition: 0.5s;

    svg {

        path {
            transition: 0.5s;
        }
    }

    &.__primary {
        font-size: 28px;
        font-weight: 400;
        line-height: 120%;
        padding: 10px 20px;
        background-color: $color_white;
        color: $color_bordo;

        @media (max-width: $mobile) {
            font-size: 18px;
        }

        &:hover {
            background-color: $color_l_orange;
            color: $color_white;
        }
    }

    &.__secondary {
        font-size: 28px;
        font-weight: 400;
        line-height: 120%;
        padding: 12px 20px;
        border-radius: 4px;

        @media (max-width: $tablet) {
            padding: 10px 28px;
        }

        @media (max-width: $mobile) {
            font-size: 18px;
            padding: 8px 12px;
        }

        &.__white {
            background-color: $color_white;
            color: $color_bordo;

            &:hover {
                background-color: $color_bordo;
                color: $color_white;
            }
        }

        &.__bordo {
            background-color: $color_bordo;
            color: $color_white;

            &:hover {
                background-color: $color_orange;
            }
        }

        &.__orange {
            background-color: $color_l_orange;
            color: $color_white;

            &:hover {
                background-color: $color_bordo;
            }
        }
    }

    &.__copy {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 58px;
        max-width: 58px;
        height: 58px;
        background-color: $color_white;
        border: 2px solid $color_bordo;
        border-radius: 4px;

        @media (max-width: $mobile) {
            min-width: 40px;
            max-width: 40px;
            height: 40px;
            border-width: 1px;
        }

        &.__active {
            background-color: $color_l_orange;
            border-color: $color_l_orange;
            pointer-events: none;
            cursor: auto;

            svg {

                &:nth-child(1) {
                    opacity: 0;
                }

                &:nth-child(2) {
                    opacity: 1;
                }
            }
        }

        &:hover {
            background-color: $color_bordo;

            svg {

                path {
                    stroke: $color_white;
                }
            }
        }

        svg {
            position: absolute;
            transition: 0.5s;

            @media (max-width: $mobile) {
                width: 16px;
                height: 16px;
            }

            &:nth:child(1) {
                opacity: 1;
            }

            &:nth-child(2) {
                opacity: 0;
            }
        }
    }

    &.__share {
        position: relative;

        &.__active {

            .btn__items {

                .btn__items_box {
                    transform: translateY(0%);
                }
            }

            .btn__trigger {
                background-color: $color_bordo;

                svg {

                    path {
                        fill: $color_white;
                    }
                }
            }
        }

        .btn__items {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            transform: translateY(-95%);
            overflow: hidden;

            .btn__items_box {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: $color_l_orange;
                padding: 16px 0;
                border-radius: 4px 4px 0 0;
                transform: translateY(100%);
                transition: 0.5s;

                .btn__item {

                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }

                    &:hover {

                        svg {

                            path {
                                fill: $color_bordo;
                            }
                        }
                    }

                    svg {

                        @media (max-width: $mobile) {
                            width: 28px;
                            height: 28px;
                        }
                    }
                }
            }
        }

        .btn__trigger {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 58px;
            max-width: 58px;
            height: 58px;
            background-color: $color_white;
            border: 2px solid $color_bordo;
            border-radius: 4px;
            z-index: 1;

            @media (max-width: $mobile) {
                min-width: 40px;
                max-width: 40px;
                height: 40px;
                border-width: 1px;
            }

            &:hover {
                background-color: $color_bordo;

                svg {

                    path {
                        fill: $color_white;
                    }
                }
            }

            svg {

                @media (max-width: $mobile) {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

* {
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 0; 
    }

    p {
        font-feature-settings: 'ss06' on;
    }
}


body {
    background-color: $color_bordo;
    color: $color_white;
    line-height: 1;
    min-width: 320px;
    font-family: $primary_font;

    .__site_wrapper {
        overflow: hidden;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    * {
        -webkit-tap-highlight-color: transparent; 
    }

    main {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        min-height: 100vh;
    }
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
    color: $color_white;
}

img, svg {
    display: block;
}
@import '../../styles/vars';

.footer {
    position: relative;
    z-index: 10;
    background-color: $color_d_bordo;
    padding: 48px 0 75px;
    transition: 0.5s;

    @media (max-width: $tablet) {
        padding: 32px 0 80px;
    }

    .footer_inner {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: $mobile) {
            flex-direction: column;
        }

        .footer__logo {

            &.__left {
                max-width: 180px;

                @media (max-width: $desk_min) {
                    max-width: 144px;
                }
            }

            &.__right {
                max-width: 170px;
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translateY(100%);

                @media (max-width: $mobile) {
                    position: static;
                    transform: translateY(0);
                    margin-top: 70px;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .footer__items {
            max-width: 1200px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            @media (max-width: $desk_min) {
                max-width: 1100px;
            }

            @media (max-width: 1350px) {
                max-width: 800px;
                margin-top: -20px;
            }

            @media (max-width: $tablet) {
                max-width: 540px;
            }

            @media (max-width: $mobile) {
                max-width: 100%;
                margin-top: -15px;
                margin-left: -20px;
            }

            .footer__item {
                width: calc((100% / 4) - 40px);
                margin-left: 40px;

                @media (max-width: $desk_min) {
                    width: calc((100% / 4) - 20px);
                    margin-left: 20px;
                }

                @media (max-width: 1350px) {
                    width: calc((100% / 2) - 20px);
                    margin-left: 20px;
                    margin-top: 20px;
                }

                @media (max-width: $mobile) {
                    margin-top: 40px;
                }

                .footer__item_title {
                    font-family: $secondary_font;
                    text-transform: uppercase;
                    color: $color_white;
                    margin-bottom: 15px;
                    opacity: 0.4;
                }

                .footer__item_links {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .footer__item_link {
                        position: relative;

                        &:hover {

                            &::before {
                                width: 100%;
                                left: 0;
                            }
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            width: 0%;
                            height: 2px;
                            bottom: 0;
                            right: 0;
                            background-color: $color_white;
                            border-radius: 20px;
                            pointer-events: none;
                            transition: 0.5s;
                        }
                    }
                }
            }
        }
    }
}
@import '../../styles/vars';

.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(137.59% 62.76% at 50% 50%, #FFF 27.15%, #F47A4C 80.88%);
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: 0.5s;

    &.__active {
        opacity: 1;
        pointer-events: all;
    }

    .loader__lottie {
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        width: 100%;
        height: 100%;

        div {
            width: 100%;
        }

        &:nth-child(1) {

            @media (max-width: $desk_min) {
                display: none;
            }
        }

        &:nth-child(2) {
            display: none;

            @media (max-width: $desk_min) {
                display: flex;
            }

            @media (max-width: $tablet) {
                display: none;
            }
        }

        &:nth-child(3) {
            display: none;

            @media (max-width: $tablet) {
                display: flex;
            }

            @media (max-width: $mobile) {
                display: none;
            }
        }

        &:nth-child(4) {
            display: none;

            @media (max-width: $mobile) {
                display: flex;
            }
        }
    }
}